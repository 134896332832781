<template>
	<div id="app">
		<div class="header">
			<div class="v_title">海南天耀冠网络科技有限公司</div>
			<a class="v_select" href="#home" :class="currIndex === 0 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(0)">首页</a>
			<a class="v_select" href="#product" :class="currIndex === 1 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(1)">产品</a>
			<a class="v_select" href="#about" :class="currIndex === 2 ? 'bg_yellow' : 'bg_aliceblue'" @click="onClick(2)">关于我们</a>
    </div>
    <div class="v_content" id="home">
      <div style="flex-direction: column;">
        <div style="font-size: 48px">公司简介</div>
        <p style="font-size: 18px; margin-top: 15px; line-height: 25px">
        公司致力于电商产品的开发与运营，依托在供应链领域多年积累的经验，公司自主开发了“宝宝砍价”小程序和快应用产品，与国内外多家知名电商平台合作，经过不断的探索和完善，在产品、技术、运营、推广等方面逐渐形成了自己独特的风格和模式，现公司拥有经验丰富的电商产品运营团队和强大的技术开发队伍，未来将致力于电商平台运营、供应链开拓等工作。
        </p>
      </div>
      <img
        style="width: 56%;"
        src="./assets/icon01.png"
        alt=""
      />
    </div>
    <div class="v_prodect" id="product">
    <img style="width: 42%;" src="./assets/icon04.png" alt=""/>
      <div style="flex-direction: column;margin-left: 38px;">
        <div style="font-size:48px;margin-top: 190px;">宝宝砍价</div>
        <div style="font-size: 18px; margin-top: 15px; line-height: 25px">
        宝宝砍价是一款综合性电商平台软件，致力于服务中国最广大的消费者，主打日常好货，致力于服务中国最广大的普通消费者，海量产品、众多活动全都能够满足你！
        </div>
      </div>
    </div>
    <div class="v_content_About" id="about">
      <div style="flex-direction: column">
        <div style="font-size: 35px; margin-top: 160px">关于我们</div>
        <div class="v_text" style="width: 60%;">
    公司在电商领域经过多年发展，现已在技术、产品、运营、内容领域积累丰富的经验，利用自己强大的产品开发能力和丰富的商品渠道，与国内外众多电商平台合作，将优质的产品快速投入市场，并取得不错的结果。并且公司拥有自己的产品开发团队和运营团队，未来将在产品用户体验、商品物流、售后服务等多方面综合发展。
        </div>
      </div>
      <img
        style="width: 30%;"
        src="./assets/icon03.png"
        alt=""
      />
    </div>
    <div class="footr">
      <div>
        <p style="font-size: 10px">
          CopyRight © 2020 - 2021 海南天耀冠网络科技有限公
          版权所有备案编号：<a href="https://beian.miit.gov.cn">琼ICP备2021002918号-1</a>
        </p>
        <p style="font-size: 10px">
          公司地址：海南省海口市龙华区龙华路39-1号国寿大厦2201室-009号
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currIndex: 0,
    };
  },
  methods: {
    onClick(i) {
      this.currIndex = i;
    },
  },
};
</script>

<style >
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #333;
  min-height: 100%;
  background-color: #FFF5F5;
  padding: 0 8%;
}
html,body{
	height: 100%;
	margin: 0;
}
a{
	text-decoration: none;
}
.banner {
  width: 100%;
  height: 580px;
}
.header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  /*justify-content: center;*/
  align-items: center;
  height: 15%;
  width: 100%;
  background-color: #FFF5F5;
}
.v_title {
  font-size: 30px;
  font-weight: 600;
  margin-right: 300px;
}
.v_select {
  cursor: pointer;
  margin: 0% 5%;
  font-size: 14px;
  font-weight: 700;
}
.bg_yellow {
  color: #FB3732;
}
.bg_aliceblue {
  color: #333;
}
.v_content {
  display: flex;
  flex-direction: row;
  min-height: 80%;
  margin-top: 15%;
}
.swper_view {
  display: flex;
  flex-direction: row;
}
.v_prodect {
  display: flex;
  margin-top: 20px;
}
.pro_img_view {
  display: flex;
  justify-content: space-around;
  margin-left: 15%;
  margin-right: 15%;
  height: 450px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.v_content_About {
	display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.footr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.index_view {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.v_text {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>